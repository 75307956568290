<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col>
        <v-row justify="center">
          <img src="@/assets/blue.png" width="300px" />
        </v-row>
        <v-row justify="center">
          <p>Welcome to HAMP</p>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { VCol, VContainer, VRow } from "vuetify/lib";

export default {
  components: { VCol, VContainer, VRow },
  // path: "/home",
  // name: "Home",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1,
h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
